import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, alpha } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { jbColors } from 'app/theme/jbColors';
import {
  selectEbkServices,
  selectLastAccessedEBankingService,
  selectMarketLinkService,
} from 'features/entities/entitiesSelectors';
import { Toolbar } from 'features/toolbar/Toolbar';
import { openExternalServiceStart } from 'features/extNavigation/extNavigationSlice';

import { EBankingServiceCard } from '.';
import background from 'common/assets/jb-hq-background.jpg';
import { ServiceCode, ServiceCodes } from 'features/entities/entitiesTypes';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundAttachment: 'scroll',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      position: 'relative',
    },

    top: {
      flex: '1.84 0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.common.black, 0.3),
    },

    bottom: {
      flex: '1 0 auto',
      backgroundColor: jbColors.app.almostWhite,
    },

    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'auto',
      '-webkit-overflow-scrolling': 'touch',
      zIndex: 1,
    },

    toolbarContainer: {
      width: '100%',
      flex: '0 0 auto',
      color: theme.palette.common.white,
    },

    serviceContainer: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
    },

    title: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '22px',
      letterSpacing: '-0.2px',
      marginTop: theme.spacing(3.5),
      marginBottom: theme.spacing(1.5),
    },

    label: {
      fontWeight: 300,
      lineHeight: '22px',
      letterSpacing: '-0.408px',
      marginBottom: theme.spacing(2.5),
    },

    whiteOutline: {
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white,
    },

    marginTop: {
      marginTop: theme.spacing(8),
    },
    serviceLinkLabel: {
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: 1.5,
      letterSpacing: '-0.32px',
      paddingLeft: theme.spacing(0.5),
    },

    ebkServicesList: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },

    marketLinkButton: {
      marginTop: theme.spacing(3),
      fontSize: theme.typography.pxToRem(13),
      letterSpacing: '0.1em',
    },
  })
);

const sortedEbkServiceCodes: ServiceCode[] = [
  ServiceCodes.DIG_SG,
  ServiceCodes.DIG_HK,
  ServiceCodes.DIG_SG_LEGACY,
  ServiceCodes.DIG_HK_LEGACY,
  ServiceCodes.EBK,
  ServiceCodes.EBK_R20,
  ServiceCodes.ESE_LU,
  ServiceCodes.EL2_LU,
  ServiceCodes.ESE_MC,
  ServiceCodes.EL2_MC,
  ServiceCodes.EL2_GG,
  ServiceCodes.EL2_CH,
  ServiceCodes.INO2,
  ServiceCodes.KOS,
  ServiceCodes.LSB_APAC_MOBILE,
];

const newEbkServiceCodes: ServiceCode[] = [
  ServiceCodes.EL2_LU,
  ServiceCodes.EL2_MC,
  ServiceCodes.EL2_GG,
  ServiceCodes.EL2_CH,
];

export const HomeEBankingActivated = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [oneCardTopMargin, setOneCardTopMargin] = useState(0);

  const firstCardRef = useRef<HTMLLIElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const lastAccessedEBankingService = useSelector(selectLastAccessedEBankingService);
  const ebkServices = useSelector(selectEbkServices).sort((a, b) => {
    // lastAccessedEBankingService goes first
    if (a.code === lastAccessedEBankingService) {
      return -1;
    }
    return sortedEbkServiceCodes.indexOf(a.code) - sortedEbkServiceCodes.indexOf(b.code);
  });

  const marketLinkService = useSelector(selectMarketLinkService);

  const handleMarketLink = () => {
    dispatch(openExternalServiceStart(marketLinkService!));
  };

  // Calculate margin top for single ebk card
  useEffect(() => {
    if (ebkServices.length === 1 && toolbarRef.current && firstCardRef.current && topRef.current) {
      const topMargin =
        topRef.current.offsetHeight -
        toolbarRef.current.offsetHeight -
        firstCardRef.current.offsetHeight / 2;
      setOneCardTopMargin(topMargin);
    }
  }, [ebkServices, setOneCardTopMargin]);

  return (
    <div className={classes.container}>
      <div className={classes.top} ref={topRef}></div>
      <div className={classes.bottom}></div>
      <div className={classes.content}>
        <div className={classes.toolbarContainer} ref={toolbarRef}>
          <Toolbar />
        </div>
        <div
          className={classes.serviceContainer}
          style={{
            // if we have one EBK we have special positioning for its card
            justifyContent: ebkServices && ebkServices.length === 1 ? 'initial' : 'flex-end',
          }}
        >
          <ul
            className={classes.ebkServicesList}
            style={{
              // if we have one EBK we have special positioning for its card
              marginTop: ebkServices && ebkServices.length === 1 ? oneCardTopMargin : 0,
            }}
          >
            {ebkServices.map((service, idx) => (
              <EBankingServiceCard
                ref={idx === 0 ? firstCardRef : null}
                key={`ebanking-card-${idx}`}
                service={service}
                size={idx === 0 ? 'normal' : 'small'}
                isNew={newEbkServiceCodes.includes(service.code)}
              />
            ))}
          </ul>

          {marketLinkService && (
            <Button
              className={classes.marketLinkButton}
              variant="text"
              color="primary"
              onClick={handleMarketLink}
            >
              {t('home.actions.marketLink')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
