import { EnumLiteralsOf } from 'common/types';

export type TargetEnvItem = EnumLiteralsOf<typeof TargetEnvItems>;

export const TargetEnvItems = Object.freeze({
  devcloud: 'devcloud',
  production: 'production',
} as const);

export type MapImplementationItem = EnumLiteralsOf<typeof MapImplementationItems>;

export const MapImplementationItems = Object.freeze({
  device: 'device',
  mock: 'mock',
  autoDetect: 'auto-detect',
} as const);

declare global {
  interface Window {
    REACT_APP_TARGET_ENV?: TargetEnvItem;
    REACT_APP_MAP_IMPLEMENTATION?: MapImplementationItem;
    REACT_APP_API_URL?: string;
    REACT_APP_MEMBER_ZONE_URL?: string;
    REACT_APP_ORIGINS_WHITELIST?: string[];
    // insights iframe
    insights?: Window;
  }
}

export const TARGET_ENV =
  window.REACT_APP_TARGET_ENV || process.env.REACT_APP_TARGET_ENV || TargetEnvItems.production;

export const MAP_IMPLEMENTATION =
  window.REACT_APP_MAP_IMPLEMENTATION || process.env.REACT_APP_MAP_IMPLEMENTATION;

export const API_URL = window.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
export const MEMBER_ZONE_URL =
  window.REACT_APP_MEMBER_ZONE_URL || process.env.REACT_APP_MEMBER_ZONE_URL;
export const ORIGINS_WHITELIST =
  window.REACT_APP_ORIGINS_WHITELIST ||
  process.env.REACT_APP_ORIGINS_WHITELIST?.split(',')
    .map((s) => s.trim())
    .filter((s) => s.startsWith('http://') || s.startsWith('https://')) ||
  [];

export const MIN_SPLASH_MS = 1000; //min time to display splash screen
export const EASING_BEZIER = [0.42, 0, 0.58, 1]; // animations curve
export const GENERAL_CONTACT_TEL_NUMBER = '+41 58 888 45 45';

// This is intended for DevCloud only
export type Scenario =
  | '@landing-domicile:CH-terms'
  | '@landing-domicile:CH'
  | '@landing-domicile:ES'
  | '@landing-domicile:IL'
  | '@landing-domicile:US'
  | 'client-domicile:CH-ebk-hk-ino2'
  | 'client-domicile:CH-ebk-new-terms'
  | 'client-domicile:CH-ebk-sg-hk-ino2'
  | 'client-domicile:CH-legacy-sg-hk'
  | 'client-domicile:CH-ebk'
  | 'client-domicile:CH-kos'
  | 'client-domicile:LU-el2'
  | 'client-domicile:MC-elm'
  | 'client-domicile:GG-el2'
  | 'client-domicile:CH-el2'
  | 'client-domicile:LU-eselu-r20'
  | 'client-domicile:LU-el2-elm'
  | 'client-domicile:SG-betapps-hk'
  | 'expired-trial-domicile:CH'
  | 'firstTimeUser'
  | 'prospect-domicile:CH-mem'
  | 'trial-domicile:CH-mem'
  | 'forceUpdate';

export const scenarios: Scenario[] = [
  // Landing screen - no E-Services - has accepted T&C
  '@landing-domicile:CH-terms',
  // Landing screen - no E-Services - no T&C
  '@landing-domicile:CH',
  // Landing screen - no E-Services
  '@landing-domicile:ES',
  // Landing screen - no E-Services
  '@landing-domicile:IL',
  // Landing screen - no E-Services
  '@landing-domicile:US',
  // E-Banking India - new platform
  'client-domicile:CH-ebk-hk-ino2',
  // E-Banking Switzerland - hasn't accepted new T&C
  'client-domicile:CH-ebk-new-terms',
  // E-Banking Switzerland, Singapore, Hong Kong and India
  'client-domicile:CH-ebk-sg-hk-ino2',
  // E-Banking Singapore, Hong Kong and legacy Singapore, Hong Kong
  'client-domicile:CH-legacy-sg-hk',
  // E-Banking Switzerland
  'client-domicile:CH-ebk',
  // E-Services Germany
  'client-domicile:CH-kos',
  // E-Banking Luxemburg
  'client-domicile:LU-el2',
  // E-Banking Monaco
  'client-domicile:MC-elm',
  // E-Banking Guernsey
  'client-domicile:GG-el2',
  // E-Banking Switzerland (new)
  'client-domicile:CH-el2',
  // E-Services Europe authorization and Switzerland R-20 service
  'client-domicile:LU-eselu-r20',
  // E-Banking Europe authorization and E-Banking Monaco service
  'client-domicile:LU-el2-elm',
  // BetaApps Hong Kong
  'client-domicile:SG-betapps-hk',
  // Expired trial
  'expired-trial-domicile:CH',
  // No E-Services, No Domicile, No T&C
  'firstTimeUser',
  // MEM E-Service as prospect
  'prospect-domicile:CH-mem',
  // MEM E-Service as trial
  'trial-domicile:CH-mem',
  // Needs to update the app
  'forceUpdate',
];

const searchParams = new URLSearchParams(window.location.search);
export const scenario: Scenario = (searchParams.get('scenario') as Scenario) || 'firstTimeUser';

export { contactFormLinks } from './contactForms';
export { appStoreLinks } from './appStoreLinks';
